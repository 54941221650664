import React from "react";
import { Page } from "../../components/Page";
import { Title } from "../../components/Title";
import { Content } from "../../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PrinciplesPage = () => (
  <Page language="sv" page="Principer">
    <Row>
      <Col>
        <Title>Principer för varningar</Title>

        <Content as="ul">
          <li>
            Ett varningsmeddelande skickas ut i de farligaste
            väderförhållandena, där det krävs särskild försiktighet och
            omsorgsfullhet eller till och med hjälpmedel för att hållas på
            benen.
          </li>
          <li>
            De som vill kan också få en varning på natten, men detta kan inte
            garanteras. Varningen utfärdas av en jourhavande, vars nattliga
            arbetstid beror på totalbehovet av vinterunderhåll på gatorna.
          </li>
          <li>
            Mottagaren av varningen har möjlighet att välja om han eller hon
            vill ta emot det nattliga varningsmeddelandet om halka genast, kl.
            6, kl. 7, kl. 8 eller först kl. 9. På helgen sänds varningen en
            timme senare.
          </li>
          <li>
            Om den farliga halkan fortsätter nästa dag sänds en ny varning om
            detta på morgonen.
          </li>
          <li>Det meddelas inte när halkan är över.</li>
        </Content>

        <Content>
          För att det inte ska ”gå inflation” i varningarna är förutsättningen
          för att ett meddelande ska skickas ut att över hälften av invånarna i
          området upplever att väglaget är mycket halt.
        </Content>

        <Content>
          <b>
            Man måste alltså vara försiktig även om inget meddelande har kommit.
            Tjänsten ändrar inte på något sätt stadens eller fastigheternas
            ansvar att åtgärda halka eller fotgängarens eget ansvar.
          </b>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default PrinciplesPage;
